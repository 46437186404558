<template>
  <div class="w-full pt-5 pb-5">
    <div v-if="hasAccessModule">
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({
              name: 'OrganisationlGoal',
              query: { activeTab: 'Current Goals' },
            })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878e99; height: 1.5rem"
        />

        <div style="width: 150px">
          <h1 class="text-xl text-left font-extrabold ml-4 mt-3">Edit Goal</h1>
        </div>

        <div class="w-7/12 mt-4">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>

      <div style="height: 100%" class="mt-20" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <ValidationObserver rules="required" v-slot="{ handleSubmit }">
          <div class="px-3">
            <card class="p-5 mt-3">
              <p class="text-lg font-bold">Goal Details</p>
              <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
                <div class="col-span-4">
                  <div class="text-sm text-jet mb-1">Financial Year</div>
                  <div class="date-flex mt-1 bg-disabled">
                    <datepicker
                      placeholder="--Select Day & Month & Year--"
                      style="width: 100%; outline: none"
                      input-class="date-input"
                      format="yyyy"
                      minimum-view="year"
                      :disabled="true"
                      v-model="payload.year"
                    />
                  </div>
                </div>

                <div class="col-span-4">
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    class="label mt-1"
                    v-model="payload.description"
                    :rules="['required']"
                    label="Description"
                  />
                </div>

                <div class="col-span-4">
                  <c-select
                    label="Template"
                    placeholder="Select Template"
                    variant="w-full"
                    :options="templateOptions"
                    class="label mt-1"
                    v-model="payload.templateId"
                    @input="selectedTemplate"
                    :disabled="true"
                  />
                </div>
              </div>

              <div
                class="w-full p-2 h-auto flex flex-col mt-5 cycle"
                v-if="checkTemplate"
              >
                <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

                <card class="w-full fcard">
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="doccycle" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #490166">
                        {{ cycleTitle }}
                      </div>
                      <div class="w-full fcard__text">
                        {{ cycleDescription }}
                      </div>
                    </div>
                  </div>
                </card>

                <div class="w-full h-auto flex flex-wrap">
                  <card class="w-5/12 flex flex-grow mr-3 fcard">
                    <div class="w-full flex h-auto">
                      <div class="fcard__icon">
                        <icon :icon-name="calendarIcon" size="csm" />
                      </div>
                      <div class="w-full flex flex-col h-auto">
                        <div class="fcard__header" style="color: #e99323">
                          Cycle Period
                        </div>
                        <div class="w-full fcard__text">
                          {{
                            $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd")
                          }}
                          -
                          {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                        </div>
                      </div>
                    </div>
                  </card>

                  <card
                    class="w-5/12 flex flex-grow mb-3 fcard"
                    v-for="(cycles, index) in appraisal_cycles"
                    :key="index"
                  >
                    <div class="w-full flex h-auto">
                      <div class="fcard__icon">
                        <icon :icon-name="reviewIcon" size="csm" />
                      </div>
                      <div class="w-full flex flex-col h-auto">
                        <div class="fcard__header" style="color: #2176ff">
                          {{ cycles.name }}
                        </div>
                        <div class="w-full fcard__text">
                          {{
                            $DATEFORMAT(
                              new Date(cycles.appraisal_starts),
                              "MMMM dd"
                            )
                          }}
                          -
                          {{
                            $DATEFORMAT(
                              new Date(cycles.appraisal_ends),
                              "MMMM dd"
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>

              <div
                class="w-full p-2 h-auto flex flex-col mt-5"
                style="border: 1px dashed #878e99; border-radius: 5px"
                v-else
              >
                <div class="w-11/12 h-6 p-1 flex cyc-text">
                  PERFORMANCE DETAILS
                </div>
                <div class="w-11/12 h-6 p-1 mt-4 mb-3 inner-text">
                  --Select a Performance Template to view Appraisal Cycle
                  details here---
                </div>
              </div>
            </card>
          </div>

          <div class="px-3">
            <card class="p-5" style="margin-top: 4px">
              <div
                class="w-full flex flex-row"
                style="margin-top: 20px; height: 44px; flex-grow: 1"
              >
                <div class="w-9/12 h-auto mt-2">
                  <h1 class="text-base font-bold">KPAs</h1>
                </div>

                <div class="h-full w-auto flex flex-row" v-if="checkTemplate">
                  <div
                    class="p-2 mx-3 bg-ghostWhite h-full flex items-center"
                    style="border-radius: 5px"
                  >
                    <span class="text-sm font-black flex flex-grow mt-2">
                      Total Allocated Weight:
                    </span>
                    <span class="text-sm text-desire ml-3 mt-2">
                      {{ allocatedWeight }}%
                    </span>
                    <icon :icon-name="iconInfo" size="ksm" />
                  </div>
                </div>
              </div>

              <div style="border-bottom: 1px solid #878e99; height: 1rem" />

              <div class="flex flex-col w-full my-4">
                <!----- List of KPA's ---->

                <div class="w-full h-auto flex flex-col">
                  <accordion
                    class="mt-6"
                    v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                    :key="kpaNumber"
                    show-divider
                    @open="handleOpen($event, kpaTypes.id)"
                    :is-open="true"
                  >
                    <template v-slot:title>
                      <span class="font-bold">{{ kpaTypes.name }}</span>
                    </template>

                    <template v-slot:editIcon>
                      <div
                        class="flex ml-4 text-blueCrayola cursor-pointer"
                        @click="weightAdjust = true"
                      >
                        <icon icon-name="edit" size="xs" />
                      </div>
                    </template>

                    <template v-slot:block>
                      <div
                        class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                      >
                        <span
                          class="font-black text-xs"
                          :class="
                            getKPAWeight(kpaTypes, kpaTypes.id) >
                            getTotalWeight(kpaTypes, kpaTypes.id)
                              ? 'text-desire'
                              : ''
                          "
                        >
                          {{ getKPAWeight(kpaTypes, kpaTypes.id) }}
                        </span>
                        <span class="font-black text-xs"> of </span>
                        <span class="font-black text-xs">
                          {{ getTotalWeight(kpaTypes, kpaTypes.id) }}%
                        </span>
                      </div>
                    </template>

                    <div class="w-full">
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <div class="w-full flex justify-end">
                          <div
                            class="text-flame font-semibold flex mb-2 p-2 mr-3 text-base cursor-pointer"
                            @click="handleSubmit(createNewKPA(kpaTypes))"
                          >
                            <icon
                              icon-name="icon-plus"
                              class="mr-2"
                              size="xsm"
                            />
                            Add New KPI
                          </div>
                        </div>

                        <scroll-container height="1000px" style="width: 99%">
                          <Table
                            :headers="KPAHeader"
                            :items="kpaTypes.kpas"
                            class="h-full my-2 table"
                            aria-label="absence table"
                            :has-number="false"
                          >
                            <template v-slot:item="{ item }">
                              <div v-if="item.state" class="-ml-3 kpas-err">
                                <div v-if="item.data.state" class="p-2">
                                  <div class="flex flex-col">
                                    <div
                                      class="cursor-pointer"
                                      v-if="item.data.isLocked"
                                      @click="item.data.isLocked = false"
                                    >
                                      <icon
                                        icon-name="icon-lock"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                    <div
                                      class="cursor-pointer"
                                      v-else
                                      @click="item.data.isLocked = true"
                                    >
                                      <icon
                                        icon-name="icon-unlock"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                    <div
                                      class="cursor-pointer"
                                      @click="deleteKPA(item.index, kpaTypes)"
                                      v-if="!item.data.isLocked"
                                    >
                                      <icon
                                        icon-name="icon-trash"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-else-if="item.name" class="kpas-err">
                                <div class="label">
                                  <c-text
                                    placeholder="--Enter--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-1 w-72"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.name"
                                    v-tooltip.bottom-middle="item.data.name"
                                    :rules="['required']"
                                    label="KPI"
                                  />
                                </div>
                              </div>

                              <div v-else-if="item.objective" class="kpas-err">
                                <div class="label">
                                  <c-text
                                    placeholder="--Description--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-2 w-72"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.objective"
                                    v-tooltip.bottom-middle="
                                      item.data.objective
                                    "
                                    :rules="['required']"
                                    label="Objective"
                                  />
                                </div>
                              </div>

                              <div v-else-if="item.kpis" class="flex kpas-err">
                                <div
                                  class="label flex"
                                  v-for="(n, rating) in item.data.kpis"
                                  :key="rating"
                                >
                                  <ValidationObserver>
                                    <c-text
                                      variant="w-full"
                                      :class="`mr-2 -mt-2 w-48`"
                                      :placeholder="`Criteria ${
                                        lowest_rating + rating
                                      }`"
                                      :disabled="item.data.isLocked"
                                      v-model="item.data.kpis[rating].criteria"
                                      :rules="['required']"
                                      v-tooltip.bottom-middle="
                                        item.data.kpis[rating].criteria
                                      "
                                      :label="`Criteria ${rating} value`"
                                    />
                                  </ValidationObserver>
                                </div>
                              </div>
                              <div v-else-if="item.target_date" class="-mt-2">
                                <div class="label w-72">
                                  <label class="text-sm mb-1">Target date <span data-v-d5a084b0="" class="text-base font-bold text-razzmataz">*</span></label>

                                  <div
                                    class="date-flex"
                                    :class="
                                      item.data.isLocked ? 'bg-disabled' : ''
                                    "
                                  >
                                    <datepicker
                                      placeholder="--Select Day & Month & Year--"
                                      style="width: 100%; outline: none"
                                      input-class="date-input"
                                      v-model="item.data.target_date"
                                      :disabled="item.data.isLocked"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div v-else-if="item.weight" class="kpas-err">
                                <div class="label">
                                  <c-text
                                    placeholder="--Enter Weight--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-2 w-24"
                                    type="number"
                                    min="0"
                                    max="100"
                                    :rules="weightRules"
                                    v-model="item.data.weight"
                                    :disabled="item.data.isLocked"
                                    label="Weight"
                                  />
                                </div>
                              </div>
                            </template>
                          </Table>
                        </scroll-container>
                      </ValidationObserver>
                    </div>
                  </accordion>
                </div>
              </div>

              <div
                class="w-full flex p-1 mt-2"
                v-if="payload.templateId !== ''"
              >
                <radio-button
                  :options="optionBox"
                  col-span="col-span-4"
                  class="inline-block text-darkPurple mr-4 gap-x-12 radio"
                  @change="getSelected($event)"
                  v-model="payload.shareWith"
                />
              </div>
            </card>
          </div>

          <div class="pl-3 flex mt-5">
            <div class="flex flex-grow">
              <Button
                :class="disableBtn && `btn-disabled`"
                :background="appearance.buttonColor || defaultBTNColor"
                :style="disableBtn ? `color: #878E99` : `color: #FFFFFF`"
                width="8rem"
                :disabled="disableBtn"
                @click="handleSubmit(submit)"
              >
                Save
              </Button>
              <Button
                background-color="none"
                class="ml-4"
                style="color: #321c3b"
                @click="
                  $router.push({
                    name: 'OrganisationlGoal',
                    query: { activeTab: 'Current Goals' },
                  })
                "
              >
                Cancel
              </Button>
            </div>
            <div class="flex justify-end mr-5">
              <Button
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
                width="8rem"
                @click="submit('draft')"
                v-if="payload.status === 'draft'"
              >
                Save Draft
              </Button>
            </div>
          </div>

          <RightSideBar
            v-if="weightAdjust"
            @close="weightAdjust = false"
            submit="Save"
            @submit="saveWeight"
            button-class="bg-dynamicBackBtn text-white"
          >
            <template v-slot:title>
              <div class="w-full flex">
                <p class="text-darkPurple mt-3">Edit Weight</p>
              </div>
            </template>
            <template v-slot:subtitle>
              <div class="flex flex-col w-full mb-3">
                <p class="my-2 text-base">Edit KPA Weights</p>
              </div>
            </template>

            <div class="w-full flex flex-col">
              <div class="w-full flex py-4 mb-1">
                <span
                  class="text-base text-darkPurple flex flex-grow mt-2 font-semibold"
                >
                  Total Weight
                </span>
                <c-text
                  placeholder=""
                  variant="w-full"
                  class="-mt-2 w-20"
                  :disabled="true"
                  v-model="totalWeight"
                />
              </div>

              <div class="w-full flex flex-col">
                <div
                  class="w-full flex py-4 mb-1"
                  v-for="(kpaTypes, kpaNumber) in kpaWeights"
                  :key="kpaNumber"
                >
                  <span class="text-base text-darkPurple flex flex-grow mt-2">
                    {{ kpaTypes.name }}
                  </span>
                  <div class="flex flex-col w-20 mr-2 -ml-1">
                    <span class="flex flex-col relative">
                      <span
                        class="w-full"
                        style="
                          height: 40px;
                          padding: 10px 15px;
                          width: 100%;
                          border-radius: 5px;
                          color: #878e99;
                          background-color: #eeeeee;
                          line-height: 120%;
                          letter-spacing: 0.015em;
                          text-align: center;
                          font-weight: 600;
                        "
                        :class="
                          kpaTypes.weight > kpaTypes.totalKpiWeight
                            ? `text_desire`
                            : ``
                        "
                      >
                        {{ kpaTypes.weight }}
                      </span>
                    </span>
                  </div>
                  <span class="text-base text-darkPurple flex mt-2 mr-2 items-center">
                    of
                  </span>
                  <c-text
                    placeholder=""
                    variant="w-full"
                    class="-mt-2 w-20"
                    type="number"
                    v-model="kpaTypes.totalKpiWeight"
                    @input="computeKPAWeights"
                  />
                </div>
              </div>
            </div>
          </RightSideBar>

          <RightSideBar
            v-if="addOthersModal"
            @close="addOthersModal = false"
            submit="Proceed"
            @submit="employeeSubmit"
            :button-class="`bg-dynamicBackBtn text-white`"
          >
            <template v-slot:title>
              <div class="w-full flex">
                <p class="text-darkPurple mt-3">Add Others</p>
              </div>
            </template>
            <template v-slot:subtitle>
              <div class="flex flex-col w-full mb-3">
                <p class="my-2 text-base">Search existing employees to add.</p>
              </div>
            </template>

            <search-input
              class="my-4"
              @input="startSearch"
              placeholder="Search"
            />

            <div style="height: 100%" v-if="loadingBar">
              <loader size="xxs" :loader-image="false" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in employees"
                :key="index"
                class="flex flex-col py-4 userContainer border-b border-dashed"
              >
                <div class="flex items-center">
                  <div>
                    <img
                      class="mr-3"
                      :src="item.photo"
                      v-if="item.photo"
                      alt="user photo"
                      style="height: 35px; width: 35px; border-radius: 5px"
                    />
                    <div
                      style="height: 35px; width: 35px; border-radius: 5px"
                      class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.fname} ${item.lname}`) }}
                    </div>
                  </div>
                  <div class="flex flex-col flex-grow">
                    <p>
                      {{ `${item.fname} ${item.lname}` }}
                    </p>
                    <div
                      class="text-xs flex font-semibold w- text-romanSilver uppercase"
                    >
                      <p class="opacity-50 mr-2" style="font-size: 10px">
                        {{
                          `${item.orgFunction ? item.orgFunction.name : "---"}`
                        }}
                      </p>
                      <p class="opacity-50 ml-1" style="font-size: 10px">
                        <span
                          class="dot dot-circle secondary"
                          style="width: 6px; height: 6px"
                        />
                      </p>
                      <p class="opacity-50 ml-2" style="font-size: 10px">
                        {{
                          `${
                            item.userDesignation
                              ? item.userDesignation.name
                              : "---"
                          }`
                        }}
                      </p>
                    </div>
                  </div>
                  <checkbox
                    checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
                    label-style="color:#333333; "
                    class="mr-6"
                    :value="[item]"
                    v-model="item.selected"
                  />
                </div>
              </div>
              <p
                class="text-flame ml-4 mt-10 cursor-pointer"
                @click="getAllEmployees(`&page=${pageNo}`)"
              >
                {{ showMore ? "Show More" : "Show Less" }}
              </p>
            </div>
          </RightSideBar>
        </ValidationObserver>
      </div>
    </div>

    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import RadioButton from "@/components/RadioButton";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    ErrorComponent,
    Breadcrumb,
    Icon,
    CText,
    RadioButton,
    Card,
    Button,
    Checkbox,
    RightSideBar,
    SearchInput,
    CSelect,
    ScrollContainer,
    Accordion,
    Table,
    Datepicker,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Organisational Goals and Objectives",
          href: "Goals",
          id: "Goals",
        },
        {
          disabled: false,
          text: "Edit Goal",
          id: "Edit goal",
        },
      ],
      optionBox: [
        { name: "Share with CEO", radioName: "share", value: "ceo" },
        {
          name: "Share with CEO & Direct Reports",
          radioName: "share",
          value: "ceo_and_direct_reports",
        },
        {
          name: "Share with CEO & Others",
          radioName: "share",
          value: "ceo_and_others",
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPI", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      KPAData: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      illustration: "illustration",
      weightAdjust: false,
      allocatedWeight: 0,
      templateKPATypes: [],
      loadingBar: true,
      hasAccessModule: false,
      loading: true,
      showMore: false,
      lowest_rating: "",
      pageNo: "",
      employees: [],
      kpaWeights: [],
      totalWeight: "",
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      checkTemplate: false,
      edit: false,
      watchOthers: false,
      shareWithCeo: false,
      addOthersModal: false,
      maxWeight: false,
      previousGoalModal: false,
      selectedEmployee: "",
      Ratings: [],
      criteriaNumber: "",
      templateOptions: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      kpaData: [],
      payload: {
        orgId: "",
        templateId: "",
        description: "",
        year: "",
        status: "draft",
        kpa_types: [],
        shareWith: "",
        sharedEmployees: [],
      },
    };
  },
  computed:{
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  watch: {
    selectedTemplate(value) {
      this.getOneTemplate(value);
      this.checkTemplate = true;
    },
  },

  methods: {
    getSelected(value) {
      if (value === "ceo_and_others") {
        this.addOthersModal = true;
      }
    },

    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    computeKPAWeights() {
      let sum = 0;

      this.kpaWeights.forEach((kpa) => {
        sum += Number(kpa.totalKpiWeight);
      });

      this.totalWeight = sum;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.kpaWeights.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalKpiWeight;
        }
        return {};
      });

      return sum;
    },

    saveWeight() {
      const payload = { kpa_types: [] };

      this.kpaWeights.map((kpaWeight) => {
        payload.kpa_types.push({
          id: kpaWeight.id,
          weight: Number(kpaWeight.totalKpiWeight),
        });
        return {};
      });

      if (this.totalWeight === 100) {
        this.$_addKPAWeights(payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.$toasted.error("Total KPA Weight must be equal to 100%", {
          duration: 5000,
        });
      }
    },

    getOneTemplate(templateId) {
      this.$_getOneTemplate(templateId).then((response) => {
        this.cycleDescription =
          response.data.PerformanceTemplate.agreement_description;

        this.cycleTitle = response.data.PerformanceTemplate.agreement_title;

        this.payload.templateId = templateId;

        this.templateKPATypes = response.data.PerformanceTemplate.kpa_types;

        this.templateKPATypes.forEach((kpaType) => {
          const kpaData = kpaType;

          const newKPAs = [];

          if (kpaType.kpas.length > 0) {
            kpaType.kpas.map((kpasData) => {
              newKPAs.push({
                state: true,
                isLocked: kpasData.isLocked,
                name: kpasData.name,
                objective: kpasData.objective,
                kpis: kpasData.kpi,
                target_date: kpasData.target_date,
                weight: kpasData.weight,
              });
              return {};
            });

            kpaData.kpas = newKPAs;
          } else {
            kpaType.kpas.push({
              state: true,
              isLocked: false,
              name: null,
              objective: null,
              kpis: this.getCriteriaData(),
              target_date: null,
              weight: "",
            });
          }

          return {};
        });

        this.loading = false;
      });
    },

    startSearch(value) {
      this.employees = [];
      if (value !== "") {
        const searchQuery = `&search=${value}&page=1`;
        this.loadingBar = true;
        this.employeeSearch = true;
        this.getEmployeesSearch(searchQuery);
      } else {
        this.employeeSearch = false;
        this.getAllEmployees("");
      }
    },

    employeeSubmit() {
      const employeeSelected = this.employees.some(
        (employee) => employee.selected === true
      );

      if (employeeSelected) {
        this.addOthersModal = false;
        this.$toasted.success("Employees Selected", { duration: 5000 });
      } else {
        this.$toasted.error("Select one or more employees to proceed", {
          duration: 5000,
        });
      }
      return {};
    },

    submit(status) {
        this.kpaData = [];
        let isSatisfied = true;

        this.templateKPATypes.forEach((kpaType) => {
          const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
            ...rest,
            target_date: this.formatDate(rest.target_date, "arrange"),
          }));

          const validation = newKPAs.every(
            (item) => item.name || item.objective || item.weight
          );

          if (validation) {
            this.kpaData.push({
              kpa_types_id: kpaType.id,
              kpas: newKPAs,
            });
          } else {
            this.kpaData.push({
              kpa_types_id: kpaType.id,
              kpas: [],
            });
          }

          return {};
        });

        this.payload.kpa_types = this.kpaData;
        this.payload.orgId = this.$orgId;
        this.payload.year = this.payload.year.getFullYear();
        this.payload.sharedEmployees = [];

        if (this.payload.shareWith === "ceo_and_others") {
          this.employees.filter((employee) => {
            if (employee.selected) {
              this.payload.sharedEmployees.push(employee.userid);
            }
            return {};
          });
        } else {
          this.payload.sharedEmployees = [];
        }

        if (this.payload.shareWith === "") {
          delete this.payload.shareWith;
        }

        this.kpaWeights.map((kpaWeight) => {
          if (kpaWeight.weight > kpaWeight.totalKpiWeight) {
            isSatisfied = false;
          }
          return {};
        });

        if (!status) {
          this.payload.status = "active";
        }

        this.payload.id = this.$route.params.id;

        if (isSatisfied) {
          this.$_editShortTermGoal(this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.$router.push({
                name: "OrganisationlGoal",
                query: { activeTab: "Current Goals" },
              });
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
              this.payload.year = new Date(this.payload.year, 0);
            });
        } else {
          this.$toasted.error(
            "Allocated weights of some KPA Types is greater than their set weight",
            {
              duration: 5000,
            }
          );
          this.payload.year = new Date(this.payload.year, 0);
          if (status) {
            this.payload.status = "draft";
          }
      }
    },

    validateWeight(weight) {
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      return true;
    },

    deleteKPA(index, kpaTypes) {
      if (kpaTypes.kpas.length > 1) {
        kpaTypes.kpas.splice(index, 1);
      } else {
        this.$toasted.error(
          "Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead",
          { duration: 5000 }
        );
      }
    },

    handleOpen(state) {
      if (state) {
        if (this.allocatedWeight > 100) {
          this.disableBtn = false;
        } else {
          this.disableBtn = true;
        }
      } else {
        this.disableBtn = true;
      }
    },

    createNewKPA(kpa) {
      const arrayObj = kpa.kpas[kpa.kpas.length - 1];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: null,
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%. To add new KPI, reduce any existing KPA or KPI weight(s)",
            { duration: 7000 }
          );
        }
      }
    },

    getCriteriaData() {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      this.lowest_rating = Math.min.apply(
        this.Ratings,
        this.Ratings.map((item) => item.rating)
      );

      this.Ratings.map((rating) => {
        criteriaData.push({
          performanceRatingId: rating.id,
          criteria: "",
        });
        return {};
      });

      return criteriaData;
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate("").then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.template_type !== "draft") {
            this.templateOptions.push({
              id: template.id,
              name: template.title,
            });
          }
          return {};
        });

        this.loading = false;
      });
    },

    getEmployeesSearch(searchQuery) {
      this.employees = [];
      this.$_getAllEmployeesWithQueries(searchQuery).then((response) => {
        this.loadingBar = false;
        response.data.data.employees.map((employee) => {
          if (employee.userReportingTo) {
            this.employees.push({
              id: employee.id,
              userid: employee.userId,
              fname: employee.fname,
              lname: employee.lname,
              orgFunction: employee.orgFunction,
              userDesignation: employee.userDesignation,
              photo: employee.photo,
              selected: false,
            });
          }
          return {};
        });
      });
    },

    getAllEmployees(query) {
      this.loadingBar = true;
      this.$_getAllEmployeesWithQueries(query).then((response) => {
        this.loadingBar = false;
        response.data.data.employees.map((employee) => {
          if (employee.userReportingTo) {
            this.employees.push({
              id: employee.id,
              userid: employee.userId,
              fname: employee.fname,
              lname: employee.lname,
              orgFunction: employee.orgFunction,
              userDesignation: employee.userDesignation,
              photo: employee.photo,
              selected: false,
            });
          }
          return {};
        });

        if (response.data.data.meta.nextPage) {
          this.pageNo = response.data.data.meta.nextPage;
          this.showMore = true;
        } else {
          this.pageNo = response.data.data.meta.prevPage
            ? response.data.data.meta.prevPage
            : 1;
          this.showMore = false;
        }
      });
    },

    getOneGoal() {
      this.$_getOneShortTermGoal(this.$route.params.id).then((response) => {
        const goalData = response.data.goal;

        this.payload.description = goalData.description;
        this.payload.templateId = goalData.templateId;
        this.payload.status = goalData.status;
        this.payload.year = new Date(goalData.year, 0);
        this.payload.shareWith = goalData.shareWith;

        this.$_getOneTemplate(goalData.templateId).then((responseData) => {
          this.$_getKPAWeights(goalData.templateId).then((result) => {
            const kpaData = result.data.data;

            this.kpaWeights = kpaData;

            let sum = 0;

            this.kpaWeights.forEach((kpa) => {
              sum += kpa.totalKpiWeight;
            });

            this.totalWeight = sum;
          });

          this.cycleDescription =
            responseData.data.PerformanceTemplate.agreement_description;

          this.cycleTitle =
            responseData.data.PerformanceTemplate.agreement_title;

          this.checkTemplate = true;
        });

        this.templateKPATypes = goalData.goal_performance_templates.kpa_types;
        this.templateKPATypes.forEach((kpaType) => {
          const kpaData = kpaType;

          const newKPAs = [];

          if (kpaType.kpas.length > 0) {
            kpaType.kpas.map((kpasData) => {
              newKPAs.push({
                state: true,
                isLocked: kpasData.isLocked,
                name: kpasData.name,
                objective: kpasData.objective,
                kpis: kpasData.kpi,
                target_date: kpasData.target_date,
                weight: kpasData.weight,
              });
              return {};
            });

            kpaData.kpas = newKPAs;
          } else {
            kpaType.kpas.push({
              state: true,
              isLocked: false,
              name: null,
              objective: null,
              kpis: this.getCriteriaData(),
              target_date: null,
              weight: "",
            });
          }
        });

        this.shareWithCeo = goalData.shareWithCeo;
        this.watchOthers = goalData.shareWithOthers;
        this.payload.sharedEmployees = goalData.sharedEmployees;

        this.timer = setTimeout(() => {
          goalData.sharedEmployees.map((data) => {
            this.employees.filter((employee) => {
              const employeeData = employee;

              if (employee.userid === data) {
                employeeData.selected = true;
              }
              return {};
            });
            return {};
          });
        }, 2000);

        if (this.watchOthers) {
          this.edit = true;
        }

        return {};
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.map((appraisalCycles) => {
          const startDate = this.setDates(appraisalCycles.cycle_start_date);

          const endDate = this.setDates(appraisalCycles.cycle_end_date);

          this.cycleStartPeriod = startDate.toISOString();

          this.cycleEndPeriod = endDate.toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = this.setDates(cycle.appraisal_starts);

            const endCycleDate = this.setDates(cycle.appraisal_ends);

            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: startCycleDate.toISOString(),
              appraisal_ends: endCycleDate.toISOString(),
            });
          });

          return {};
        });
      });
    },

    watchTotalWeight() {
      let sum = 0;
      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum !== 100) {
        this.maxWeight = false;
        this.disableBtn = true;
      } else {
        this.maxWeight = true;
        this.disableBtn = false;
      }

      this.allocatedWeight = sum;
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "organisationalGoals",
        "viewCurrentOrganisationalGoals"
      );
      this.hasAccessModule = true;
      this.$_getPerfomanceRating().then((response) => {
        this.Ratings = response.data.PerformanceRatings;
        this.getTemplates();
        this.getAllEmployees("");
        this.getOneGoal();
        this.getAppraisalCycles();
        setInterval(() => {
          this.watchTotalWeight();
        }, 1000);
      });
    } catch (error) {
      this.hasAccessModule = false;
    }
  },
};
</script>

<style>
.kpas-err small {
  margin-bottom: -1.4rem;
}

#text-input--error {
  border: 1px solid rgba(238, 43, 9, 0.952);
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
  outline: 0;
}

.text_desire {
  color: rgba(234, 60, 83, 1) !important;
}

.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: block;
  font-size: 10px;
  margin: 0;

}


.head {
  width: max-content;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}
</style>
